// Footer.tsx
import React from "react";
import { AppBar, Box, Toolbar, Typography, Link } from "@mui/material";

const Footer: React.FC = () => {
  return (
    <AppBar
      position="fixed"
      elevation={0}
      sx={{ top: "auto", bottom: 0, width: "100%" }}
    >
      <Box
        sx={{
          boxShadow: "0 -2px 4px rgba(0, 0, 0, 0.2)",
        }}
      >
        <Toolbar
          sx={{
            backgroundColor: "#ffffff",
            borderTop: "1px solid #CCCCCC",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              justifyContent: "center",
            }}
          >
            <Box sx={{ mx: 2 }}>
              <Typography color="black"><Link href="https://trim.nagoya/">企業情報</Link></Typography>
            </Box>
            <Box sx={{ mx: 2 }}>
              <Typography
                color="black"
                sx={{ borderLeft: "1px solid #000000", paddingLeft: 4 }}
              >
                <Link href="https://trim.nagoya/privacypolicy/">個人情報の取り扱いについて</Link>
              </Typography>
            </Box>
            <Box sx={{ mx: 2 }}>
              <Typography
                color="black"
                sx={{ borderLeft: "1px solid #000000", paddingLeft: 4 }}
              >
                <Link href="https://cadtrace.jp/form/">お問い合わせ</Link>
              </Typography>
            </Box>
            <Box sx={{ mx: 2 }}>
              <Typography
                color="black"
                sx={{ borderLeft: "1px solid #000000", paddingLeft: 4 }}
              >
                © Trim Co., Ltd. All Rights Reserved.
              </Typography>
            </Box>
            <Box sx={{ mx: 2 }}>
              <Typography
                color="black"
                sx={{ borderLeft: "1px solid #000000", paddingLeft: 4 }}
              >
                AI技術の活用によるCADトレース見積もり業務の自動化
              </Typography>
            </Box>
          </Box>
        </Toolbar>
      </Box>
    </AppBar>
  );
};

export default Footer;
