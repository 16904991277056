import React, { useMemo, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Formik, Form, Field, useFormikContext } from "formik";
import {
  Button,
  TextField,
  Container,
  ThemeProvider,
  createTheme,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import Stack from "@mui/material/Stack";
import "./styles.css";
import axios from "axios";

// スタブ
// import { getDisableDays } from "../stub/api/getDisableDays";
// ローディング
import LoadingDialog from "../components/LoadingDialog/LoadingDialog";
// カレンダー用フィールド
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import jaLocale from "date-fns/locale/ja";
import { jaJP } from "@mui/x-date-pickers/locales";
import { isAfter, isWeekend, addDays, isEqual } from "date-fns";
// バリデーション
import * as yup from "yup";
// カスタイマイズしたもの
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import { theme } from "../components/Color/color";
import { FormValues } from "../interfaces/formValues";
import { toJSTISOString } from "../components/Utils/utils";

// redux
import { useAppSelector, useAppDispatch } from "../app/hooks";
import { changeDisplay } from "../features/managementProcess/managementProcessSlice";
import {
  update,
  customerInfoState,
  selectCustomerInfo,
} from "../features/customerInfo/customerInfoSlice";

// コンポーネントを定義
const InputCustomerInfo: React.FC = () => {
  // redux
  const customerInfo: customerInfoState = useAppSelector(selectCustomerInfo);
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(changeDisplay("InputCustomerInfo"));
  }, [dispatch]);

  // エラーメッセージの定義
  const CustomErrorMessage: React.FC<{ name: keyof FormValues }> = ({
    name,
  }) => {
    const { values, errors } = useFormikContext<FormValues>();

    let message = "";
    let style = {};

    if (name === "remarks") {
      if (errors[name]) {
        // エラー表示は黄色
        message = errors[name] as string;
        style = { backgroundColor: "#FFFACD", color: "black" };
      } else {
        message = "任意の文字列を入力してください。空欄OK";
        style = { backgroundColor: "#98FB98", color: "black" };
      }
      return (
        <div className="customErrorMessage" style={style}>
          {message}
        </div>
      );
    }
    if (name === "deliveryDate") {
      if (!values[name]) {
        // 初回表示は黄色
        message = "希望納期を選択してください。";
        style = { backgroundColor: "#FFFACD", color: "black" };
      } else {
        message = "OK";
        style = { backgroundColor: "#98FB98", color: "black" };
      }
      return (
        <div className="customErrorMessage" style={style}>
          {message}
        </div>
      );
    }

    if (errors[name]) {
      // エラー表示は黄色
      message = errors[name] as string;
      style = { backgroundColor: "#FFFACD", color: "black" };
    } else if (!values[name]) {
      // 初回表示は黄色
      message = "必須項目です。";
      style = { backgroundColor: "#FFFACD", color: "black" };
    } else {
      // 問題ないときは緑色
      message = "OK";
      style = { backgroundColor: "#98FB98", color: "black" };
    }

    return (
      <div className="customErrorMessage" style={style}>
        {message}
      </div>
    );
  };

  // フォームの初期値
  const initialValues = {
    email: customerInfo.email !== "" ? customerInfo.email : "",
    pic: customerInfo.pic !== "" ? customerInfo.pic : "",
    picRuby: customerInfo.picRuby !== "" ? customerInfo.picRuby : "",
    companyName:
      customerInfo.companyName !== "" ? customerInfo.companyName : "",
    companyNameRuby:
      customerInfo.companyNameRuby !== "" ? customerInfo.companyNameRuby : "",
    postalCode: customerInfo.postalCode !== "" ? customerInfo.postalCode : "",
    address: customerInfo.address !== "" ? customerInfo.address : "",
    address2: customerInfo.address2 !== "" ? customerInfo.address2 : "",
    phoneNumber:
      customerInfo.phoneNumber !== "" ? customerInfo.phoneNumber : "",
    deliveryDate:
      customerInfo.deliveryDate !== null
        ? new Date(customerInfo.deliveryDate)
        : null,
    projectName:
      customerInfo.projectName !== "" ? customerInfo.projectName : "",
    remarks: customerInfo.remarks !== "" ? customerInfo.remarks : "",
  };

  // フォームのバリデーションスキーマ
  const validationSchema = yup.object({
    email: yup
      .string()
      .matches(/^[^'"/\\;]*$/, "記号は使用できません。")
      .email("有効なメールアドレスを入力してください。")
      .required("必須項目です。"),
    pic: yup.string().required("必須項目です。"),
    picRuby: yup
      .string()
      .matches(/^[^'"/\\;]*$/, "記号は使用できません。")
      .matches(/^[\u30A0-\u30FF]+$/, "カタカナで入力してください")
      .required("必須項目です。"),
    companyName: yup
      .string()
      .matches(/^[^'"/\\;]*$/, "記号は使用できません。")
      .required("必須項目です。"),
    companyNameRuby: yup
      .string()
      .matches(/^[^'"/\\;]*$/, "記号は使用できません。")
      .matches(/^[\u30A0-\u30FF]+$/, "カタカナで入力してください")
      .required("必須項目です。"),
    postalCode: yup
      .string()
      .matches(/^[^'"/\\;]*$/, "記号は使用できません。")
      .matches(/^\d{3}-\d{4}$/, "有効な郵便番号を入力してください。")
      .required("必須項目です。"),
    address: yup
      .string()
      .matches(/^[^'"/\\;]*$/, "記号は使用できません。")
      .required("必須項目です。"),
    address2: yup
      .string()
      .matches(/^[^'"/\\;]*$/, "記号は使用できません。")
      .required("必須項目です。"),
    phoneNumber: yup
      .string()
      .matches(/^[^'"/\\;]*$/, "記号は使用できません。")
      .matches(/^\d{10,11}$/, "有効な電話番号を入力してください。")
      .required("必須項目です。"),
    deliveryDate: yup.date().nullable().required("必須項目です。"),
    projectName: yup
      .string()
      .matches(/^[^'"/\\;]*$/, "記号は使用できません。")
      .required("必須項目です。"),
    remarks: yup.string().matches(/^[^'"/\\;]*$/, "記号は使用できません。"),
  });

  // Date型をstring型に変換してFormValuesをシリアライズする関数
  const serializeFormValues = (values: FormValues): customerInfoState => {
    return {
      ...values,
      deliveryDate: values.deliveryDate
        ? toJSTISOString(values.deliveryDate)
        : "",
      status: "",
    };
  };

  // 確認画面へボタン押下時
  const navigate = useNavigate();
  const [validationErrors, setValidationErrors] = useState<string[]>([]);
  const [open, setOpen] = useState(false);
  const onSubmit = (values: FormValues) => {
    try {
      // 日付フォーマットだけDate型なのでシリアライズ可能な形に変換
      const serialized = serializeFormValues(values);
      dispatch(update(serialized));
      navigate("/input-info-confirmation");
    } catch (error) {
      console.error("ストア更新でエラーが発生しました", error);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [isLoading, setIsLoading] = useState<boolean>(true);

  /**
   * 2つの日付オブジェクトが同じ日付かどうかを判定する関数
   * @param date1 - 比較する最初の日付オブジェクト
   * @param date2 - 比較する2番目の日付オブジェクト
   * @returns 2つの日付オブジェクトが同じ日付の場合はtrue、そうでない場合はfalse
   */
  const isSameDay = (date1: Date, date2: Date): boolean => {
    if (!(date1 instanceof Date) || !(date2 instanceof Date)) {
      console.error("Invalid Date objects:Date1:", date1);
      console.error("Invalid Date objects:Date2:", date2);
      return false;
    }

    return (
      date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate()
    );
  };
  /**
   * 文字列をISO形式の日付に変換する関数
   * @param dateString - ISO形式の日付文字列 (例: "2023-04-20")
   * @returns 変換された日付オブジェクト
   */
  const parseISO = (dateString: string): Date => {
    const [year, month, day] = dateString.split("-").map(Number);
    return new Date(year, month - 1, day);
  };

  // DatePickerをFormikと連携させるコンポーネント
  const DatePickerField: React.FC = () => {
    const { setFieldValue, values, validateField } =
      useFormikContext<typeof initialValues>();
    const [disabledDates, setDisabledDates] = useState<string[]>([]);
    // const datePickerRef = useRef<HTMLInputElement | null>(null);

    useEffect(() => {
      // Lambda関数から非稼働日を取得
      const fetchDisabledDates = async () => {
        try {
          const response = await axios.get(
            "https://6o556yrzjc.execute-api.ap-northeast-1.amazonaws.com/stage/non-operating-days"
          );
          setDisabledDates(response.data);
        } catch (error) {
          console.error("Error fetching disabled dates:", error);
        } finally {
          setIsLoading(false);
        }
      };

      fetchDisabledDates();
    }, []);
    // 日付用のアダプタ
    class DateAdapter extends AdapterDateFns {
      // 参考サイトの実装例よりも、端折っているが、日曜始まりが固定なら以下で十分。
      getWeekdays = (): string[] => ["日", "月", "火", "水", "木", "金", "土"];
    }
    //DatePicker用
    const TODAY = useMemo(() => new Date(), []);
    const calendarTheme = createTheme(
      {},
      jaJP // x-date-pickers translations
    );

    const isDateDisabled = useMemo(() => {
      return (date: Date) => {
        // const disableDate = new Date(disabledDates[0]);
        // console.log(date);
        // console.log(disableDate);
        const tomorrow = addDays(TODAY, 1); // 翌日の日付を取得
        // 過去の日付は無効化
        if (isAfter(TODAY, date)) {
          return true;
        }

        // 当日または未来の土日は無効化
        if (isSameDay(TODAY, date)) {
          return true;
        }
        // 翌日は無効化
        if (isSameDay(tomorrow, date)) {
          return true;
        }
        // 週末は無効化
        if (isWeekend(date)) {
          return true;
        }

        // APIから取得した任意の無効日は無効化
        if (disabledDates.some((d) => isEqual(date, parseISO(d)))) {
          return true;
        }

        // それ以外は有効化
        return false;
      };
    }, [TODAY, disabledDates]);
    const handleDateChange = (date: Date | null) => {
      setFieldValue("deliveryDate", date);
      validateField("deliveryDate");
    };
    return (
      <ThemeProvider theme={calendarTheme}>
        <LocalizationProvider
          dateAdapter={DateAdapter}
          adapterLocale={jaLocale}
        >
          <Box style={{ margin: "10px 0px" }}>
            <DatePicker
              label="希望納期"
              value={values.deliveryDate}
              onChange={handleDateChange}
              inputFormat="yyyy年MM月dd日"
              shouldDisableDate={isDateDisabled}
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputProps={{ ...params.InputProps, readOnly: true }}
                  inputProps={{ ...params.inputProps, disabled: true }}
                />
              )}
            />
          </Box>
        </LocalizationProvider>
      </ThemeProvider>
    );
  };

  // フォームのスタイル定義
  const formStyle = {
    margin: "20px 10px 5px 0px",
  };

  // 確認画面へのボタン押下時に不正なフィールドの名前を表示するための辞書
  const fields = [
    { name: "email", label: "Email" },
    { name: "pic", label: "担当者のお名前" },
    { name: "picRuby", label: "担当者のお名前（カナ）" },
    { name: "companyName", label: "会社名" },
    { name: "companyNameRuby", label: "会社名（カナ）" },
    { name: "postalCode", label: "郵便番号（ハイフンあり）" },
    { name: "address", label: "住所：県市町村区" },
    { name: "address2", label: "住所：マンション名等詳細" },
    { name: "phoneNumber", label: "電話番号" },
    { name: "deliveryDate", label: "希望納期" },
    { name: "projectName", label: "案件名" },
    { name: "remarks", label: "備考" },
  ];

  return (
    <>
      <LoadingDialog isOpen={isLoading} message={"読込中"} />
      <Header pageTitle="お客様情報入力" />
      <Container style={{ marginTop: "16px", marginBottom: "100px" }}>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ errors, touched, setFieldValue, values, validateForm }) => (
            <Form>
              <Field
                name="email"
                id="email"
                as={TextField}
                label="Email"
                fullWidth
                style={formStyle}
                autoComplete="email"
              />
              <CustomErrorMessage name="email" />
              <Field
                type="text"
                name="pic"
                id="pic"
                as={TextField}
                label="担当者のお名前"
                fullWidth
                style={formStyle}
                autoComplete="name"
              />
              <CustomErrorMessage name="pic" />
              <Field
                type="text"
                name="picRuby"
                id="picRuby"
                as={TextField}
                label="担当者のお名前（カナ）"
                fullWidth
                style={formStyle}
                autoComplete="additional-name"
              />
              <CustomErrorMessage name="picRuby" />
              <Field
                type="text"
                name="companyName"
                id="companyName"
                as={TextField}
                label="会社名"
                fullWidth
                style={formStyle}
                autoComplete="organization"
              />
              <CustomErrorMessage name="companyName" />
              <Field
                type="text"
                name="companyNameRuby"
                id="companyNameRuby"
                as={TextField}
                label="会社名（カナ）"
                fullWidth
                style={formStyle}
                autoComplete="organization"
              />
              <CustomErrorMessage name="companyNameRuby" />
              <Field
                type="text"
                name="postalCode"
                id="postalCode"
                as={TextField}
                label="郵便番号（ハイフンあり）"
                fullWidth
                style={formStyle}
                autoComplete="postal-code"
              />
              <CustomErrorMessage name="postalCode" />
              <Field
                type="text"
                name="address"
                id="address"
                as={TextField}
                label="住所：県市町村区"
                fullWidth
                style={formStyle}
                autoComplete="address-level1"
              />
              <CustomErrorMessage name="address" />
              <Field
                type="text"
                name="address2"
                id="address2"
                as={TextField}
                label="住所：マンション名等詳細"
                fullWidth
                style={formStyle}
                autoComplete="address-line2"
              />
              <CustomErrorMessage name="address2" />
              <Field
                type="text"
                name="phoneNumber"
                id="phoneNumber"
                as={TextField}
                label="電話番号（ハイフン無し）"
                fullWidth
                style={formStyle}
                autoComplete="tel"
              />
              <CustomErrorMessage name="phoneNumber" />
              <Field name="deliveryDate" component={DatePickerField} />
              <CustomErrorMessage name="deliveryDate" />
              <Field
                type="text"
                name="projectName"
                id="projectName"
                as={TextField}
                label="案件名"
                fullWidth
                style={formStyle}
              />
              <CustomErrorMessage name="projectName" />
              <Field
                type="text"
                name="remarks"
                id="remarks"
                as={TextField}
                label="備考"
                fullWidth
                style={formStyle}
              />
              <CustomErrorMessage name="remarks" />
              <Box
                sx={{
                  margin: "20px 200px",
                }}
              >
                <Stack direction="row" spacing={2}>
                  <ThemeProvider theme={theme}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      fullWidth
                      onClick={async () => {
                        const errors = await validateForm();
                        if (Object.keys(errors).length > 0) {
                          setValidationErrors(
                            Object.entries(errors).map(([field, message]) => {
                              const fieldLabel =
                                fields.find((f) => f.name === field)?.label ||
                                field;
                              return `『${fieldLabel}』が正常に入力されていません: ${message}`;
                            })
                          );
                          setOpen(true);
                        } else {
                          onSubmit(values);
                        }
                      }}
                    >
                      確認画面へ
                    </Button>
                    <Button
                      variant="outlined"
                      fullWidth
                      onClick={() => {
                        //ダウンロード処理
                        navigate("/");
                      }}
                    >
                      キャンセル
                    </Button>
                  </ThemeProvider>
                </Stack>
              </Box>
            </Form>
          )}
        </Formik>
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>入力エラー</DialogTitle>
          <DialogContent>
            <DialogContentText>
              以下のフィールドにエラーがあります。修正してください。
            </DialogContentText>
            <ul>
              {validationErrors.map((error, index) => (
                <li key={index}>{error}</li>
              ))}
            </ul>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              閉じる
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
      <Footer />
    </>
  );
};

export default InputCustomerInfo;
